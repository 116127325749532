<template>
  <card body-classes="standard-card-body">
    <div slot="header">
      <h4 class="card-title text-capitalize">{{ $t('fields.tutors') }}</h4>
    </div>
    <list-selector-component :number-items-per-page="18" :list="tutorsLocal" ref="tutorSelector">
      <template v-slot:default="slotProps">
        <div>
          {{ users[slotProps.item.id] | optional('lastname') }}
          {{ users[slotProps.item.id] | optional('firstname') }}
        </div>
      </template>
    </list-selector-component>
  </card>
</template>

<script>
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import {Option, Select} from "element-ui";
import Course from "@/models/course";
import {mapGetters} from "vuex";
import NoDataComponent from "@/components/NoDataComponent";
import ListSelectorComponent from "@/components/ListSelectorComponent";

export default {
  name: "CourseTutorsCard",
  components: {
    ListSelectorComponent,
    NoDataComponent,
    ListGroupItemComponent,
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      tutorsLocal: []
    }
  },
  mounted() {
    this.$_.each(this.$_.cloneDeep(this.tutors), (item) => {
      item.selected = !!this.$_.find(this.course.tutors, tutor => item.id === tutor.id);
      this.tutorsLocal.push(item);
    });
  },
  props: {
    course: {
      type: Object,
      default: () => _.cloneDeep(Course)
    }
  },
  computed: {
    ...mapGetters({
      users: 'common/users',
      tutors: 'common/tutors'
    }),
  },
  methods: {
    getSelectedTutorIds() {
      return this.$_.map(this.$refs.tutorSelector.getSelectedItems(), tutor => tutor.id);
    },

    getSelectedTutors() {
      return this.$refs.tutorSelector.getSelectedItems();
    }
  }
}
</script>

<style scoped>

</style>
