import { render, staticRenderFns } from "./CourseModulePicker.vue?vue&type=template&id=5860b9cc&scoped=true&"
import script from "./CourseModulePicker.vue?vue&type=script&lang=js&"
export * from "./CourseModulePicker.vue?vue&type=script&lang=js&"
import style0 from "./CourseModulePicker.vue?vue&type=style&index=0&id=5860b9cc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5860b9cc",
  null
  
)

export default component.exports