<template>
  <ValidationObserver ref="courseForm">
    <card body-classes="standard-card-body">
      <div slot="header">
        <h4 class="card-title text-capitalize">{{ title }}</h4>
      </div>
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-md-6">
            <ul class="list-group list-group-flush">
              <list-group-item-component :label="$t('fields.title')" :label-width="labelWidth">
                <template slot="value">
                  <ValidationProvider
                    class="flex-grow-1"
                    name="title"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
                  >
                    <base-input
                      required
                      type="text"
                      class="mb-0"
                      v-model="course.title"
                      :error="errors[0]"
                      :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    </base-input>
                  </ValidationProvider>
                </template>
              </list-group-item-component>
              <list-group-item-component :label="$t('fields.code')" :label-width="labelWidth">
                <template slot="value">
                  <ValidationProvider
                    class="flex-grow-1"
                    name="code"
                    rules="required"
                    v-slot="{ passed, failed, errors }"
                  >
                    <base-input
                      required
                      type="text"
                      class="mb-0"
                      v-model="course.code"
                      :error="errors[0]"
                      :class="[{ 'has-success': passed }, { 'has-danger': failed }]">
                    </base-input>
                  </ValidationProvider>
                </template>
              </list-group-item-component>
              <list-group-item-component :label="$t('datatable.slug')" :label-width="labelWidth" :value="course.slug"/>
              <list-group-item-component
                v-if="course.type"
                :label="$t('fields.type')"
                :value="$t('common.' + course.type)"
              />
              <list-group-item-component :label="$t('fields.active')" :label-width="labelWidth">
                <template slot="value">
                  <el-switch class="ml-3" v-model="course.active"/>
                </template>
              </list-group-item-component>
            </ul>
          </div>
          <div class="col-12 col-md-6">
            <ul class="list-group list-group-flush">
              <list-group-item-component :label="$t('fields.lesson_number')" :label-width="labelWidth">
                <template slot="value">
                  <base-input
                    min="0"
                    class="mb-0 flex-grow-1"
                    type="number"
                    v-model="course.lesson_number"
                  />
                </template>
              </list-group-item-component>
              <list-group-item-component :label="$t('fields.participants')" :label-width="labelWidth">
                <template slot="value">
                  <div class="d-flex align-items-center">
                    <base-input
                      min="0"
                      class="mb-0 flex-grow-1"
                      type="number"
                      v-model="course.minimum_participants"
                      addonRightText="min"
                    />
                    <div class="mx-2">-</div>
                    <base-input
                      min="0"
                      class="mb-0 flex-grow-1"
                      type="number"
                      v-model="course.maximum_participants"
                      addonRightText="max"
                    />
                  </div>

                </template>
              </list-group-item-component>
              <list-group-item-component :label="$t('fields.price')" :label-width="labelWidth">
                <template slot="value">
                  <base-input
                    min="0"
                    class="mb-0 flex-grow-1"
                    type="number"
                    v-model="course.price"
                  />
                </template>
              </list-group-item-component>
              <list-group-item-component :label="$t('fields.enrollment_price')" :label-width="labelWidth">
                <template slot="value">
                  <base-input
                    min="0"
                    class="mb-0 flex-grow-1"
                    type="number"
                    v-model="course.enrollment_price"
                  />
                </template>
              </list-group-item-component>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <ul class="list-group list-group-flush">
              <list-group-item-component :label="$t('fields.description')" :label-width="labelWidth">
                <template slot="value">
                  <base-input class="mb-0 flex-grow-1">
                    <base-text-area :rows="4" v-model="course.description"/>
                  </base-input>
                </template>
              </list-group-item-component>
            </ul>
          </div>
        </div>
      </div>
    </card>
  </ValidationObserver>
</template>

<script>
import {Switch, Select, Option} from "element-ui";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import Course from "@/models/course";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import BaseTextArea from "@/components/Inputs/BaseTextArea";

export default {
  name: "CourseFormCard",
  components: {
    BaseTextArea,
    LabelThemeComponent,
    ListGroupItemComponent,
    [Switch.name]: Switch,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      labelWidth: 150,
      courseTypes: ['course', 'workshop'],
    }
  },
  props: {
    course: {
      type: Object,
      default: () => this.$_.cloneDeep(Course)
    },
    title: {
      type: String,
      default: ''
    },
  },
  methods: {
    validate() {
      return new Promise((resolve, reject) => {
        this.$refs.courseForm.validate()
          .then((success) => {
            if (success) {
              resolve(this.course);
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
}
</script>

<style scoped>

</style>
