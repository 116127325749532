<template>
  <div class="container-fluid">
    <div class="row align-items-end" v-if="!readOnly">
      <div class="col flex-grow-1">
        <base-input>
          <el-select
            class="select-default text-uppercase"
            value-key="code"
            :placeholder="$t('common.type_to_search')"
            v-model="selectedCourseModule"
          >
            <el-option
              v-for="option in courseModuleOptions"
              class="select-default text-uppercase"
              :value="option"
              :label="option.title"
              :key="option.code"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-auto form-group">
        <base-button @click="addCourseModule" icon link class="ml-auto text-capitalize mb-0">
          <octo-icon icon="add"/>
        </base-button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <ul class="list-group list-group-flush course-module-list">
          <list-group-item-component
            v-for="(courseModule, index) in courseModulesChunks[0]"
            v-bind:key="`courseModule-${index}`"
          >
            <template slot="label">
              <div class="standard-label text-truncate">
                <label-theme-component>{{ courseModule.code + ': ' }}</label-theme-component>
                {{ courseModule.title }}
              </div>
            </template>
            <template slot="value">
              <base-button
                icon
                link
                class="ml-2 my-0"
                type="danger"
                size="sm"
                v-if="!readOnly"
                @click="deleteCourseModule(courseModule.id)"
              >
                <octo-icon icon="wrong"/>
              </base-button>
            </template>
          </list-group-item-component>
        </ul>
      </div>
      <div class="col-12 col-md-6">
        <ul class="list-group list-group-flush course-module-list">
          <list-group-item-component
            v-for="(courseModule, index) in courseModulesChunks[1]"
            v-bind:key="`courseModule-${index}`"
          >
            <template slot="label">
              <div class="standard-label text-truncate">
                <label-theme-component>{{ courseModule.code + ': ' }}</label-theme-component>
                {{ courseModule.title }}
              </div>
            </template>
            <template slot="value">
              <base-button
                icon
                link
                class="ml-2 my-0"
                type="danger"
                size="sm"
                v-if="!readOnly"
                @click="deleteCourseModule(courseModule.id)"
              >
                <octo-icon icon="wrong"/>
              </base-button>
            </template>
          </list-group-item-component>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import OctoIcon from "@/components/octo-icon/OctoIcon";
import BaseAlert from "@/components/BaseAlert";
import {Select, Option} from "element-ui";
import ListGroupItemComponent from "@/components/ListGroupItemComponent";
import LabelThemeComponent from "@/components/LabelThemeComponent";
import {mapGetters} from "vuex";

export default {
  name: "CourseModulePicker",
  components: {
    LabelThemeComponent,
    ListGroupItemComponent,
    BaseAlert,
    OctoIcon,
    [Select.name]: Select,
    [Option.name]: Option
  },
  props: {
    selectedCourseModules: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedCourseModule: null,
      courseModulesLocal: [],
    }
  },
  mounted() {
    this.courseModulesLocal = this.$_.cloneDeep(this.selectedCourseModules);
  },
  computed: {
    ...mapGetters({
      courseModules: 'common/courseModules'
    }),

    courseModuleOptions() {
      const courseModuleIds = this.$_.map(this.courseModulesLocal, courseModule => {
        return courseModule?.id;
      });

      return this.$_.filter(this.courseModules, courseModule => {
        return !this.$_.find(courseModuleIds, id => {
          return courseModule.id === id;
        });
      });
    },

    courseModulesChunks() {
      return this.$_.chunk(this.courseModulesLocal, this.$_.ceil(this.courseModulesLocal.length / 2));
    }
  },
  methods: {
    getCourseModules() {
      this.$_.each(this.courseModulesLocal, (courseModule, index) => {
        this.$set(courseModule, 'order', index);
      });

      return this.courseModulesLocal;
    },

    deleteCourseModule(courseModuleId) {
      this.courseModulesLocal.splice(
        this.$_.findIndex(this.courseModulesLocal, courseModule => courseModule.id === courseModuleId),
        1
      );
    },

    addCourseModule() {
      this.courseModulesLocal.push(this.selectedCourseModule);
      this.selectedCourseModule = null;
    }
  }
}
</script>

<style scoped>
.course-module-list {
  max-height: 250px;
  overflow: auto;
}
</style>
